import React, {createContext, ReactNode, useContext, useState} from 'react';
import {Dialog, Portal} from 'react-native-paper';
import {useWindowDimensions, View} from 'react-native';

interface DialogContextType {
  openDialog: (content: ReactNode, title: string) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

export const DialogProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [visible, setVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState<ReactNode>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');

  const openDialog = (content: ReactNode, title: string) => {
    setDialogContent(content);
    setDialogTitle(title);
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
    setDialogContent(null);
  };

  const {height} = useWindowDimensions();

  const [usedHeight, setUsedHeight] = useState<number>(140);

  const calcHeight = (): number => {
    return Math.floor(height / 2 - usedHeight / 2);
  };

  return (
    <DialogContext.Provider value={{openDialog, closeDialog}}>
      <Portal>
        <Dialog
          visible={visible}
          onDismiss={closeDialog}
          style={{
            flex: 1,
            width: '80%',
            alignSelf: 'center',
            position: 'absolute',
            top: calcHeight(),
          }}>
          <View
            onLayout={e =>
              setUsedHeight(Math.floor(e.nativeEvent.layout.height))
            }>
            <Dialog.Title
              style={{alignSelf: 'center', flex: 1, alignItems: 'center'}}>
              {dialogTitle}
            </Dialog.Title>
            <Dialog.Content>{dialogContent}</Dialog.Content>
          </View>
        </Dialog>
      </Portal>
      {children}
    </DialogContext.Provider>
  );
};
