import {DefaultTheme} from 'react-native-paper';

export class ThemeManager {
  public static readonly WIDTH: number = 800;
  public static readonly MIN_WIDTH: number = 200;
}

export const MAIN_THEME = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'tomato',
    secondary: 'yellow',
  },
};
