import React from 'react';
import {AppRegistry, Platform} from 'react-native';
import App from './App';
import {PaperProvider} from 'react-native-paper';
import {DialogProvider} from './components/dialog/DialogProvider';
import {MAIN_THEME} from './util/ThemeManager';

function Main() {
  return (
    <PaperProvider theme={MAIN_THEME}>
      <DialogProvider>
        <React.Fragment>
          {Platform.OS === 'web' ? (
            <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
      `}</style>
          ) : null}
          <App />
        </React.Fragment>
      </DialogProvider>
    </PaperProvider>
  );
}

AppRegistry.registerComponent('App', () => Main);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
