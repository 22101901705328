import React, {useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import {ChessField, getCorrectText} from './ChessField.tsx';
import {ChessGame, ChessPieces} from '../util/chess/Chess.ts';
import {Button} from 'react-native-paper';
import {useDialog} from './dialog/DialogProvider.tsx';

export interface ChessBoardProps {
  size: number;
  data: ChessPieces[];
}

export const ChessBoard: React.FC<ChessBoardProps> = (
  props: ChessBoardProps,
): React.JSX.Element => {
  const [selected, setSelected] = useState<number | null>(null);
  const [marked, setMarked] = useState<Set<number>>(new Set());
  const [data, setData] = useState<ChessPieces[]>(props.data);

  const getFieldSize = (value: number): number => {
    return Math.floor(value / 8);
  };

  const [fieldSize, setFieldSize] = useState<number>(getFieldSize(props.size));

  useEffect(() => {
    setFieldSize(getFieldSize(props.size));
  }, [props.size]);

  const {openDialog, closeDialog} = useDialog();

  const makeSelectMove = (
    cSel: number,
    index: number,
    target: ChessPieces,
  ): void => {
    setData(ChessGame.getInstance().makeMove(cSel, index, target));
    setSelected(null);
    closeDialog();
  };

  const showDialog = (cSel: number, index: number): void => {
    openDialog(
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'center',
          gap: 16,
        }}>
        <Button
          mode={'contained'}
          onPress={() => makeSelectMove(cSel, index, ChessPieces.W_QUEEN)}>
          {getCorrectText(ChessPieces.W_QUEEN)}
        </Button>
        <Button
          mode={'contained'}
          onPress={() => makeSelectMove(cSel, index, ChessPieces.W_ROOK)}>
          {getCorrectText(ChessPieces.W_ROOK)}
        </Button>
        <Button
          mode={'contained'}
          onPress={() => makeSelectMove(cSel, index, ChessPieces.W_BISHOP)}>
          {getCorrectText(ChessPieces.W_BISHOP)}
        </Button>
        <Button
          mode={'contained'}
          onPress={() => makeSelectMove(cSel, index, ChessPieces.W_KNIGHT)}>
          {getCorrectText(ChessPieces.W_KNIGHT)}
        </Button>
      </View>,
      'Chose one Figure',
    );
  };

  const selectField = (index: number) => {
    if (
      selected != null &&
      ChessGame.getInstance().getChessBoard()[selected] !== ChessPieces.EMPTY
    ) {
      if (
        (data[selected] === ChessPieces.W_PAWN ||
          data[selected] === ChessPieces.B_PAWN) &&
        (index < 8 || index >= 56)
      ) {
        showDialog(selected, index);
      } else {
        setData(ChessGame.getInstance().makeMove(selected, index));
      }
    }
    setSelected(index);
  };

  useEffect(() => {
    if (selected) {
      setMarked(
        ChessGame.getInstance().getPossibleDestinations(
          selected,
          data[selected],
        ),
      );
    } else {
      setMarked(new Set());
    }
  }, [data, selected]);

  const renderFields = (): React.JSX.Element[] => {
    return data.map((item, i) => {
      const tS = selected === i;
      const tM = marked.has(i);
      return (
        <Pressable
          key={`field_${i}_${tS}_${tM}`}
          onPress={() => selectField(i)}>
          <ChessField
            size={fieldSize}
            index={i}
            piece={item}
            selected={tS}
            marked={tM}
          />
        </Pressable>
      );
    });
  };

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: fieldSize * 8,
      }}>
      {renderFields()}
    </View>
  );
};
