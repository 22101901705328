import React, {useState} from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {ChessPieces} from '../util/chess/Chess.ts';

export interface ChessFieldProps {
  size: number;
  index: number;
  piece: ChessPieces;
  selected?: boolean;
  marked?: boolean;
}
export const getCorrectText = (piece: ChessPieces): string => {
  switch (piece) {
    case ChessPieces.W_KING:
      return '♔';
    case ChessPieces.W_QUEEN:
      return '♕';
    case ChessPieces.W_ROOK:
      return '♖';
    case ChessPieces.W_BISHOP:
      return '♗';
    case ChessPieces.W_KNIGHT:
      return '♘';
    case ChessPieces.W_PAWN:
      return '♙';
    case ChessPieces.B_KING:
      return '♚';
    case ChessPieces.B_QUEEN:
      return '♛';
    case ChessPieces.B_ROOK:
      return '♜';
    case ChessPieces.B_BISHOP:
      return '♝';
    case ChessPieces.B_KNIGHT:
      return '♞';
    case ChessPieces.B_PAWN:
      return '♟';
    default:
      return ' ';
  }
};

export const ChessField: React.FC<ChessFieldProps> = React.memo(
  (props: ChessFieldProps): React.JSX.Element => {
    const [fontSize] = useState<number>(props.size * 0.8);
    const [color] = useState<string>(
      props.piece > ChessPieces.W_PAWN ? 'black' : 'white',
    );

    const getBackGroundColor = (): string => {
      const row = Math.floor(props.index / 8);
      if (row % 2 === 0) {
        if (props.index % 2 === 0) {
          // white
          return '#F2E1C3';
        }
        // black
        return '#C3A082';
      }
      if (props.index % 2 === 0) {
        // black
        return '#C3A082';
      }
      // white
      return '#F2E1C3';
    };

    const fieldStyles = StyleSheet.create({
      base: {
        width: props.size,
        height: props.size,
        backgroundColor: getBackGroundColor(),
        alignItems: 'center',
        justifyContent: 'center',
      },
      marked: {
        borderWidth: 4,
        borderColor: 'white',
      },
      selected: {
        borderWidth: 4,
        borderColor: '#ffb400',
      },
    });

    const getCorrectStyle = () => {
      if (props.selected === true) {
        return [fieldStyles.base, fieldStyles.selected];
      }
      if (props.marked === true) {
        return [fieldStyles.base, fieldStyles.marked];
      }
      return [fieldStyles.base];
    };

    return (
      <View style={getCorrectStyle()}>
        <Text
          style={{
            userSelect: 'none',
            fontSize: fontSize,
            color: color,
          }}>
          {getCorrectText(props.piece)}
        </Text>
      </View>
    );
  },
);
